<template>
  <main class="bg-background-variant-1 text-on-surface-elevation-1">
    <section
      class="relative w-full max-w-[136rem] mx-auto px-20 pt-[10.8rem] sm:px-40 sm:pt-[12.8rem] lg:px-[1.4rem] lg:pt-[14.8rem] flex flex-col md:flex-row md:items-start md:justify-between lg:items-center md:gap-[.4rem] lg:gap-40"
    >
      <div
        class="flex flex-col gap-12 sm:gap-16 w-full md:flex-1 lg:w-auto text-center md:text-left"
      >
        <h1
          class="text-[3.6rem] leading-[4.68rem] sm:text-[5.6rem] sm:leading-6xl md:leading-[1.2em] lg:leading-[1.25em] font-bold leading-tight -tracking-[.2rem]"
          :class="
            locale !== LanguageCode.Ko
              ? 'md:text-[4.8rem] lg:text-[6.4rem]'
              : 'md:text-[5.2rem] lg:text-[7rem]'
          "
        >
          <safe-html :html="$t('studio.brand_page.easy_and_fast_title')" />
        </h1>
        <p
          class="text-lg leading-lg md:text-xl lg:text-2xl lg:leading-xl text-gray-700 text-on-surface-elevation-2"
        >
          <safe-html :html="$t('studio.brand_page.easy_and_fast_guide')" />
        </p>
        <a
          href="#"
          class="absolute left-1/2 md:left-0 -bottom-[9.6rem] md:bottom-0 -translate-x-1/2 md:translate-x-0 md:relative flex items-center justify-center px-[3rem] md:px-[4rem] lg:px-[5.6rem] py-16 bg-brand-primary text-white rounded-full font-bold sm:font-medium text-[1.6rem] md:text-[1.8rem] w-max md:mt-24 z-[1]"
          :aria-label="$t('studio.brand_page.sign_up_btn')"
          @click.prevent="handleGoHome"
        >
          {{ $t('studio.brand_page.sign_up_btn') }}
        </a>
      </div>

      <div class="flex justify-end w-full md:flex-1 lg:w-auto mt-40 md:mt-0">
        <div
          class="relative w-full max-w-[56rem] sm:max-w-[68.4rem] mx-auto h-auto lg:w-[72rem] rounded-[1.5rem] overflow-hidden"
          :class="
            locale !== LanguageCode.Ko
              ? 'md:max-w-[64rem] md:min-w-[48rem] lg:max-w-[72rem]'
              : 'md:w-[48rem] md:max-w-[48rem] lg:max-w-[72rem]'
          "
        >
          <video
            ref="videoPlayer"
            :src="videoUrl"
            class="w-full h-full bg-contain"
            playsinline
            webkit-playsinline
            preload="metadata"
            controlsList="nodownload"
            x-webkit-airplay="deny"
            disablePictureInPicture
            controls
            @ended="handleVideoEnd"
          ></video>
          <div v-show="!isPlaying" class="absolute inset-0 cursor-pointer" @click="toggleVideo">
            <img
              src="@/assets/images/brand-page/brand-page-img-video-thumnail.jpg"
              alt="Tutorial video img"
              class="w-full h-auto rounded-2xl transition-transform duration-300 hover:scale-105"
              width="720"
              height="405"
            />
            <img
              src="@/assets/images/brand-page/play-icon.svg"
              alt="Play icon"
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="relative w-full mx-auto overflow-hidden">
      <section-slider />
    </section>
    <section class="bg-[#1F1F1F] md:bg-[#E1EFFF] z-[1] relative overflow-hidden">
      <section-intro-translation-services />
    </section>
    <section
      class="relative max-w-[136rem] mx-auto pt-[5.6rem] pb-[8rem] sm:pt-80 md:pb-[8.6rem] lg:pt-[12rem] lg:pb-[12rem] overflow-hidden md:overflow-visible"
    >
      <section-intro-stove-studio />
    </section>
    <section
      class="bg-chart relative mx-auto w-full pt-[8rem] pb-[6rem] sm:py-80 lg:pt-[12rem] lg:pb-[11.6rem]"
    >
      <section-chart />
    </section>
    <section
      class="bg-[#E1EFFF] sticky top-[2rem] pt-[5rem] pb-[6rem] sm:py-80 md:pb-[8.8rem] lg:pb-[8rem]"
    >
      <section-share-stove-studio />
    </section>
    <section
      class="relative bg-background-variant-1 pt-[5rem] pb-[8rem] md:py-80 lg:pb-[14rem] lg:pt-[12rem] px-20 sm:px-40 lg:px-0"
    >
      <section-footer />
    </section>
  </main>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SectionChart from '@/components/brand-page/section-chart.vue';
import SectionFooter from '@/components/brand-page/section-footer.vue';
import SectionIntroStoveStudio from '@/components/brand-page/section-intro-stove-studio.vue';
import SectionIntroTranslationServices from '@/components/brand-page/section-intro-translation-services.vue';
import SectionShareStoveStudio from '@/components/brand-page/section-share-stove-studio.vue';
import SectionSlider from '@/components/brand-page/section-slider.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import { showAlert } from '@/composables/useDialog';
import {
  GROUP_REGISTER_PAGE_URL,
  HOME_PAGE_URL,
  REDIRECT_TO_GROUP_HOME
} from '@/constants/url.const';
import { INTRO_VIDEO_URLS } from '@/constants/videos.const';
import { LanguageCode } from '@/enums/language-code.enum';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import { isMobileOrTablet } from '@/utils/mobile.util';
import { goToLoginPage, isLogin } from '@/utils/user.utils';
import { definePageMeta } from '#imports';

definePageMeta({
  layout: 'brand',
  showTutorial: false
});

const { t, locale } = useI18n();

const userStore = useUserStore();

const videoPlayer = ref<HTMLVideoElement | null>(null);
const isPlaying = ref(false);

const videoUrl = computed(() => {
  return INTRO_VIDEO_URLS[locale.value] || INTRO_VIDEO_URLS[LanguageCode.Ko];
});

const handleVideoEnd = () => {
  isPlaying.value = false;
  if (videoPlayer.value) {
    videoPlayer.value.currentTime = 0;
  }
};

const toggleVideo = async () => {
  if (!videoPlayer.value) {
    return;
  }

  try {
    if (!isPlaying.value) {
      await videoPlayer.value.play();
      isPlaying.value = true;
    }
  } catch (error) {
    // console.error('Error playing video:', error);
  }
};

const handleGoHome = async () => {
  if (isMobileOrTablet()) {
    showAlert({
      content: t('studio.brand_page.mobile_app_sign_up_alert'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    return;
  }

  if (isLogin()) {
    const groupId = await userStore.findReadableGroupHome();

    if (groupId === '') {
      return await redirectTo(GROUP_REGISTER_PAGE_URL);
    }
    return await redirectTo(HOME_PAGE_URL, { groupId });
  } else {
    goToLoginPage(REDIRECT_TO_GROUP_HOME);
  }
};
</script>

<style scoped>
.bg-chart {
  background: linear-gradient(180deg, rgba(43, 102, 255, 0.3) 0%, rgba(36, 38, 42, 0) 15.96%),
    #232529;
}
:global(body.stove-studio:has(div.stds-dialog)) {
  padding-right: 0 !important;
}
</style>
