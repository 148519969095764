<template>
  <div
    ref="animationEarthRef"
    class="absolute top-[8.5rem] right-[-10.1rem] sm:top-[6.9rem] sm:right-[-17rem] md:top-[27.2rem] md:right-[-32rem] lg:top-[46.8rem] lg:right-[50%] lg:translate-x-[50%] w-[20rem] h-[18.9rem] sm:w-[34rem] sm:h-[32.1rem] md:w-[119.8rem] md:h-[113.1rem] lg:w-[149.7rem] lg:h-[141.4rem]"
  ></div>
  <div
    ref="sectionRef"
    class="relative w-full max-w-[136rem] px-20 sm:px-40 lg:px-[1.4rem] mx-auto pt-[6rem] pb-80 sm:pt-80 md:pb-[13.5rem] lg:pt-[15rem] lg:pb-[20.2rem] flex flex-col md:flex-row md:gap-[3.6rem] lg:gap-[6.8rem] md:justify-between"
  >
    <!-- Title Section -->
    <div
      class="mb-10 md:w-1/2 lg:flex-1 lg:w-auto"
      :class="locale !== LanguageCode.Ko ? 'w-[90%] sm:max-w-[51.8rem] md:max-w-fit' : 'w-full'"
    >
      <h2
        class="text-[3.6rem] leading-[1.3em] sm:text-[5.2rem] sm:leading-[1.25em] md:leading-[1.2em] font-bold leading-tight text-neutral-variant-3 md:text-on-surface-elevation-1 mt-0 lg:mt-[4.6rem]"
        :class="
          locale !== LanguageCode.Ko
            ? 'md:text-[4.8rem] lg:text-[6.4rem] -tracking-[.2rem]'
            : 'md:text-[5.2rem] lg:text-[7rem]'
        "
      >
        <safe-html :html="$t('studio.brand_page.global_service_guide0')" />
      </h2>
      <p
        class="text-md leading-lg md:text-2xl md:leading-xl text-disabled-variant-3 md:text-on-surface-elevation-2 font-medium mt-16 sm:mt-12 md:mt-16"
      >
        <safe-html :html="$t('studio.brand_page.global_sevice_guide')" />
      </p>
    </div>

    <!-- Language List -->
    <s-icon
      size="3xl"
      :icon="'ic-v2-communication-translate-line'"
      srOnlyText="sync icon"
      class="text-white text-[3.2rem] items-center w-32 h-32 mt-[6.4rem] sm:mt-[6rem] md:hidden"
    />
    <section
      aria-label="Language Options"
      class="bg-[#1F1F1F] w-full md:h-[42.5rem] lg:h-[60.8rem] md:rounded-[2.8rem] md:overflow-hidden p-0 md:p-[3.2rem] lg:p-[4.6rem] mt-12 md:mt-0 md:w-[46rem] lg:w-[66rem]"
    >
      <article class="flex flex-col items-start">
        <div
          class="text-5xl leading-4xl sm:text-6xl sm:leading-5xl md:text-[4.8rem] md:leading-[1.2em] lg:text-[7rem] lg:leading-[8.4rem] font-bold text-on-surface-elevation-3 flex flex-col md:flex-row md:flex-wrap gap-16 md:gap-x-[1.6rem] md:gap-y-[2.4rem] lg:gap-x-[2.4rem] -tracking-[.2rem]"
        >
          <div v-for="lang in languages" :key="lang.code" class="flex flex-col">
            <span
              :class="[
                currentLang === lang.code ? 'text-white' : '',
                'cursor-default transition-colors duration-200 hover:text-white'
              ]"
              @mouseenter.self="
                () => {
                  stopAutoChange();
                  changeLang(lang.code);
                }
              "
              @click.self="
                () => {
                  stopAutoChange();
                  changeLang(lang.code);
                }
              "
            >
              {{ lang.label }}
            </span>

            <p
              v-if="currentLang === lang.code"
              class="text-lg leading-lg sm:text-xl sm:leading-xl font-regular text-disabled-variant-3 mt-4 md:hidden stds-animate-fade-in"
            >
              <safe-html :html="currentText.description" />
            </p>
          </div>
        </div>
        <s-icon
          size="3xl"
          :icon="'ic-v2-control-sync-line'"
          srOnlyText="sync icon"
          class="text-white items-center w-28 h-28 lg:text-[4rem] lg:w-40 lg:h-40 md:mt-[1.8rem] lg:mt-48 md:block hidden"
        />
        <p
          :key="currentLang"
          class="md:text-[1.6rem] md:leading-[1.5em] lg:text-4xl lg:leading-3xl text-white font-medium md:mt-[1.6rem] lg:mt-20 md:block hidden stds-animate-fade-in"
        >
          <safe-html :html="currentText.description" />
        </p>
      </article>
    </section>
  </div>
</template>
<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import lottie from 'lottie-web';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import { LanguageCode } from '@/enums/language-code.enum';

gsap.registerPlugin(ScrollTrigger);

const { locale } = useI18n();

type SupportedLanguage =
  | LanguageCode.Ko
  | LanguageCode.En
  | LanguageCode.Ja
  | LanguageCode.ZhTw
  | LanguageCode.ZhCn;

type TextContent = {
  description: string;
};

type Language = {
  code: SupportedLanguage;
  label: string;
};

type Texts = {
  [key in SupportedLanguage]: TextContent;
};

const currentText = computed(() => texts[currentLang.value]);
const currentLang = ref<SupportedLanguage>(LanguageCode.Ko);
const autoChangeInterval = ref<number | null>(null);
const currentIndex = ref(0);
const timeAutoChange: number = 3000;
const isTouchDevice = ref(false);
const sectionRef = ref<HTMLElement | null>(null);
const isMobile = computed(() => window.innerWidth <= 768);

const earthAnimation = ref<any>(null);
const animationEarthRef = ref<HTMLElement | null>(null);
let animation: any = null;

const languages: Language[] = [
  { code: LanguageCode.Ko, label: '한국어' },
  { code: LanguageCode.En, label: 'English' },
  { code: LanguageCode.Ja, label: '日本語' },
  { code: LanguageCode.ZhTw, label: '繁體中文' },
  { code: LanguageCode.ZhCn, label: '简体中文' }
];

const texts: Texts = {
  [LanguageCode.Ko]: {
    description:
      '게임의 모든 취향 STOVE 스토어!<br/>AI를 통해 편리한 글로벌 서비스를 경험해 보세요.'
  },
  [LanguageCode.En]: {
    description:
      'STOVE Store - Games for Every Taste!<br/>Experience convenient global services through AI assistance.'
  },
  [LanguageCode.Ja]: {
    description:
      'あらゆる嗜好のゲームが勢揃い! STOVEストア! <br/>AIによる便利なグローバルサービスを、ぜひご体験ください。'
  },
  [LanguageCode.ZhTw]: {
    description: '擁有所有類型遊戲的STOVE商店! <br/>透過AI體驗更加簡便的全球服務。'
  },
  [LanguageCode.ZhCn]: {
    description: '综合游戏平台STOVE商店! <br/>体验AI驱动的全球化服务。'
  }
};

const changeLang = (langCode: SupportedLanguage) => {
  currentLang.value = langCode;
};

const startAutoChange = () => {
  autoChangeInterval.value = window.setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % languages.length;
    changeLang(languages[currentIndex.value].code);
  }, timeAutoChange);
};

const stopAutoChange = () => {
  if (autoChangeInterval.value) {
    clearInterval(autoChangeInterval.value);
    autoChangeInterval.value = null;
  }
};

onMounted(() => {
  isTouchDevice.value = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  startAutoChange();

  // Animation scroll
  if (sectionRef.value) {
    if (isMobile.value) {
      gsap.fromTo(
        sectionRef.value,
        {
          opacity: 0,
          y: 20
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'back.inOut',
          scrollTrigger: {
            trigger: sectionRef.value,
            start: 'top 70%',
            end: 'bottom 10%',
            toggleActions: 'play none none none'
          }
        }
      );

      if (animationEarthRef.value) {
        gsap.fromTo(
          animationEarthRef.value,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            ease: 'sine.inOut',
            scrollTrigger: {
              trigger: animationEarthRef.value,
              start: 'top 80%',
              toggleActions: 'play none none none'
            }
          }
        );
      }
    } else {
      gsap.fromTo(
        sectionRef.value,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'sine.inOut',
          scrollTrigger: {
            trigger: sectionRef.value,
            start: 'top 80%',
            end: 'bottom 10%',
            toggleActions: 'play none none none'
          }
        }
      );

      if (animationEarthRef.value) {
        gsap.fromTo(
          animationEarthRef.value,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            ease: 'sine.inOut',
            scrollTrigger: {
              trigger: animationEarthRef.value,
              start: 'top 100%',
              toggleActions: 'play none none none'
            }
          }
        );
      }
    }
  }
});

onMounted(async () => {
  // Animation Earth
  try {
    earthAnimation.value = await import('@/assets/images/brand-page/earth_pc.json');

    if (animationEarthRef.value && earthAnimation.value) {
      animation = lottie.loadAnimation({
        container: animationEarthRef.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: earthAnimation.value.default
      });
    }
  } catch (error) {
    // console.error('Failed to load animation:', error);
  }
});

onUnmounted(() => {
  stopAutoChange();

  if (animation) {
    animation.destroy();
  }
});
</script>

<style scoped>
.stds-animate-fade-in {
  animation: translation_services_fade_in 0.5s ease-out forwards;
}

@keyframes translation_services_fade_in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
