<template>
  <div
    class="relative max-w-[136rem] mx-auto px-20 sm:px-40 lg:px-80 h-[250rem] sm:h-[260rem] md:h-[140rem] lg:h-[170rem]"
  >
    <div class="sticky top-[8rem] md:top-[12rem]">
      <div ref="titleDesktopRef" class="flex-col items-center hidden md:flex">
        <img
          src="@/assets/images/brand-page/wave.svg"
          alt="wave image"
          class="w-[12.2rem] lg:w-[12rem] h-auto"
        />
        <h2
          class="text-[4.8rem] leading-[6.24rem] lg:text-[6.4rem] lg:leading-[8.4rem] text-white font-bold leading-tight mt-20"
          :class="
            locale !== LanguageCode.Ko
              ? 'md:text-[4.8rem] lg:text-[6.4rem] -tracking-[.2rem]'
              : 'md:text-[5.2rem] lg:text-[7rem]'
          "
        >
          <safe-html :html="$t('studio.brand_page.game_indicator_title')" />
        </h2>
        <p class="text-2xl leading-xl text-disabled-variant-3 font-medium mt-16">
          <safe-html :html="$t('studio.brand_page.game_indicator_guide')" />
        </p>
      </div>

      <div class="relative md:h-[50rem] lg:h-[55rem]">
        <div
          ref="containerImageScrollRef"
          class="relative w-[98.8vw] md:w-[98.9vw] lg:w-[99.3vw] left-[50%] right-[50%] -mx-[50vw] md:mt-[3rem] lg:mt-[5rem]"
        >
          <div
            ref="imageWrapperRef"
            class="overflow-hidden relative pl-[1.5rem] sm:pl-[3.5rem] md:pl-[3rem] lg:pl-[calc((100vw-136rem)/2+7rem)]"
          >
            <img
              ref="imageScrollRef"
              :src="scrollImage"
              alt="wave image"
              class="h-[18rem] !w-auto md:h-[34.4rem] !max-w-fit !relative !top-0 !left-0"
            />
          </div>
        </div>

        <div
          ref="chartDesktopRef"
          class="absolute top-0 left-1/2 -translate-x-1/2 w-[73rem] lg:w-[96.6rem] mx-auto aspect-[2.01] hidden md:block"
        >
          <div class="relative flex flex-col gap-[24%] justify-end w-full h-full">
            <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
            <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
            <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
            <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
            <div
              v-for="(bar, index) in bars"
              :key="index"
              :ref="(el) => handleBarRef(el as HTMLElement, index, 'desktop')"
              class="absolute bottom-0 rounded-[3.3717rem] md:rounded-[2rem] lg:rounded-[3.3717rem]"
              :class="[bar.position, bar.color]"
              :style="{
                height: '0%',
                width: '9.07216494845%',
                transformOrigin: 'bottom'
              }"
            ></div>
            <div
              ref="tooltipDesktopRef"
              class="flex flex-row items-center gap-4 md:gap-4 lg:gap-8 bg-white py-[0.824742268%] px-[2.0618556701%] w-fit rounded-[2rem] absolute bottom-[80.982300885%] left-[79.8969072165%] md:bottom-[80.982300885%] md:left-[78.8969072165%] lg:bottom-[80.982300885%] lg:left-[79.8969072165%]"
            >
              <s-icon
                size="3xl"
                :icon="'ic-v2-community-approve-fill'"
                srOnlyText="sync icon"
                class="text-brand-primary text-[1rem] md:text-[1.2rem] lg:text-[1.6rem] items-center w-[1rem] h-[1rem] md:w-12 md:h-12 lg:w-16 lg:h-16"
              />
              <span
                class="text-[1rem] sm:text-[2.416rem] md:text-[3.2rem] font-bold text-brand-primary"
              >133%</span>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="mobileTextRef"
        class="flex-col items-start sm:items-center flex sm:w-full md:hidden mt-[6.3rem] sm:mt-[6.5rem]"
        :class="locale !== LanguageCode.Ko ? 'max-w-[48rem] sm:max-w-full' : ''"
      >
        <h2
          class="text-[3.6rem] leading-[4.68rem] sm:text-[5.2rem] sm:leading-[6.76rem] text-white font-bold leading-tight"
        >
          <safe-html :html="$t('studio.brand_page.game_indicator_title')" />
        </h2>
        <p class="text-md leading-lg text-disabled-variant-3 font-medium mt-12">
          <safe-html :html="$t('studio.brand_page.game_indicator_guide')" />
        </p>
      </div>

      <!-- Chart Animation -->
      <div
        ref="chartMobileRef"
        class="w-full px-[0.4rem] sm:w-[56rem] md:w-[90rem] sm:px-0 mx-auto aspect-[2.02] mt-[4.5rem] sm:mt-[2.5rem] md:hidden"
      >
        <div class="relative flex flex-col gap-[24%] justify-end w-full h-full">
          <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
          <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
          <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
          <div class="w-full h-[2px] border-t border-dashed border-[#3D4249]"></div>
          <div
            v-for="(bar, index) in bars"
            :key="index"
            :ref="(el) => handleBarRef(el as HTMLElement, index, 'mobile')"
            class="absolute bottom-0 rounded-[1.1379rem]"
            :class="[bar.position, bar.color]"
            :style="{
              height: '0%',
              width: '9.07216494845%',
              transformOrigin: 'bottom'
            }"
          ></div>
          <div
            ref="tooltipRef"
            class="flex flex-row items-center gap-2 sm:gap-4 md:gap-8 bg-white py-[0.824742268%] px-[2.0618556701%] w-fit rounded-[0.675rem] sm:rounded-[1.2rem] absolute bottom-[80.982300885%] left-[79.8969072165%] sm:bottom-[81.982300885%] sm:left-[78.8969072165%] md:bottom-[80.982300885%] md:left-[79.8969072165%]"
          >
            <s-icon
              size="3xl"
              :icon="'ic-v2-community-approve-fill'"
              srOnlyText="sync icon"
              class="text-brand-primary text-[1rem] md:text-[1.2rem] lg:text-[1.6rem] items-center w-[1rem] h-[1rem] md:w-12 md:h-12 lg:w-16 lg:h-16"
            />
            <span
              class="text-[1rem] sm:text-[2.416rem] md:text-[3.2rem] font-bold text-brand-primary"
            >133%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as horizontalScrollImages from '@/assets/images/brand-page/scroll-horizontal';
import SafeHtml from '@/components/common/safe-html.vue';
import { LanguageCode } from '@/enums/language-code.enum';

type ImagesUrl = {
  [key: string]: string;
};

const { locale } = useI18n();
const prefix = 'image_';
const scrollImage = new URL(
  (horizontalScrollImages as ImagesUrl)[`${prefix}${locale.value.replace('-', '_')}`],
  import.meta.url
).href;

gsap.registerPlugin(ScrollTrigger);

const titleDesktopRef = ref<HTMLElement | null>(null);
const imageScrollRef = ref<HTMLElement | null>(null);
const chartDesktopRef = ref<HTMLElement | null>(null);
const chartMobileRef = ref<HTMLElement | null>(null);
const desktopBarRefs = ref<(HTMLElement | null)[]>([]);
const mobileBarRefs = ref<(HTMLElement | null)[]>([]);
const tooltipRef = ref<HTMLElement | null>(null);
const tooltipDesktopRef = ref<HTMLElement | null>(null);
const containerImageScrollRef = ref<HTMLElement | null>(null);
const imageWrapperRef = ref<HTMLElement | null>(null);
const mobileTextRef = ref<HTMLElement | null>(null);
const isMobile = computed(() => window.innerWidth < 1024);

const bars = [
  {
    height: '61.06194690265%',
    position: 'left-[7.6134020619%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '19.5811946902655%',
    position: 'left-[20.1350515464%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '39.162389380531%',
    position: 'left-[32.6835051546%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '57.3449115044248%',
    position: 'left-[45.2319587629%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '45.6893805309735%',
    position: 'left-[57.7793814433%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '56.8785398230088%',
    position: 'left-[70.3278350515%]',
    color: 'bg-[#3D4047]'
  },
  {
    height: '76.5949115044248%',
    position: 'left-[82.8762886598%]',
    color: 'bg-[#1355FF]'
  }
];

const handleBarRef = (el: HTMLElement | null, index: number, type: 'desktop' | 'mobile') => {
  if (!el) {
    return;
  }

  const targetRefs = type === 'desktop' ? desktopBarRefs : mobileBarRefs;

  while (targetRefs.value.length <= index) {
    targetRefs.value.push(null);
  }

  targetRefs.value[index] = el;
};

onMounted(() => {
  if (titleDesktopRef.value && !isMobile.value) {
    gsap.fromTo(
      titleDesktopRef.value,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: titleDesktopRef.value,
          start: 'top 120%',
          end: '+=80%',
          scrub: 1,
          markers: false
        }
      }
    );
  }

  if (imageScrollRef.value) {
    if (isMobile.value) {
      const tlMobile = gsap.timeline({
        scrollTrigger: {
          trigger: imageScrollRef.value,
          start: 'top 80px',
          end: '+=250%',
          scrub: 1,
          markers: false,
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true
        }
      });
      tlMobile.add(gsap.fromTo(imageScrollRef.value, { x: 0 }, { x: '-140%', duration: 1.5 }));
      tlMobile.add(
        gsap.fromTo(imageScrollRef.value, { opacity: 1 }, { opacity: 0, duration: 0.5 }),
        '+1.5'
      );
      tlMobile.add(
        gsap.to(imageWrapperRef.value, {
          height: '0',
          duration: 0.5
        }),
        '+1'
      );
      tlMobile.add(
        gsap.fromTo(
          chartMobileRef.value,
          {
            y: 100,
            opacity: 0
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.5
          }
        ),
        '+1'
      );
      tlMobile.add(
        gsap.fromTo(
          mobileBarRefs.value,
          {
            height: '0%'
          },
          {
            height: (_: number, target: HTMLElement) => {
              const barIndex = mobileBarRefs.value.indexOf(target);
              return bars[barIndex].height;
            },
            duration: 0.5,
            stagger: 0.2,
            ease: 'power2.out'
          }
        ),
        '+1'
      );
      tlMobile.add(
        gsap.fromTo(
          tooltipRef.value,
          {
            opacity: 0,
            scale: 0,
            transformOrigin: 'center center'
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1.75,
            ease: 'back.out(1.7)'
          }
        ),
        '+2.5'
      );
    } else {
      gsap.fromTo(
        imageScrollRef.value,
        { x: 0 },
        {
          x: '-140%',
          scrollTrigger: {
            trigger: imageScrollRef.value,
            start: 'top 370px',
            end: '+=60%',
            scrub: 1,
            markers: false
          }
        }
      );

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: imageScrollRef.value,
          start: 'top 370px',
          end: '+=60%',
          scrub: 1,
          markers: false,
          pin: true, // Pin the animation during scroll
          pinSpacing: false, // Prevent additional space
          invalidateOnRefresh: true
        }
      });

      tl.add(gsap.fromTo(imageScrollRef.value, { opacity: 1 }, { opacity: 0 }), 2.75);
      tl.add(
        gsap.fromTo(
          chartDesktopRef.value,
          {
            y: 100,
            opacity: 0
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.5
          }
        ),
        2.5
      );
      tl.add(
        gsap.fromTo(
          desktopBarRefs.value,
          {
            height: '0%'
          },
          {
            height: (_: number, target: HTMLElement) => {
              const barIndex = desktopBarRefs.value.indexOf(target);
              return bars[barIndex].height;
            },
            duration: 0.5,
            stagger: 0.2,
            ease: 'power2.out'
          }
        ),
        2.85
      );
      tl.add(
        gsap.fromTo(
          tooltipDesktopRef.value,
          {
            opacity: 0,
            scale: 0,
            transformOrigin: 'center center'
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1.75,
            ease: 'back.out(1.7)'
          }
        ),
        4.25
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((st: ScrollTrigger) => st.kill());
    };
  }
});
</script>
