<template>
  <div class="max-w-[136rem] mx-auto flex flex-col items-center text-center">
    <p
      class="text-md leading-sm sm:text-2xl sm:leading-xl md:text-3xl md:leading-2xl lg:text-4xl lg:leading-3xl font-bold md:font-medium text-brand-primary"
    >
      {{ $t('studio.brand_page.full_service_title1') }}
    </p>
    <h2
      class="text-[3.6rem] leading-[4.68rem] sm:text-[5.2rem] sm:leading-[6.5rem] md:text-[4.8rem] lg:text-[6.4rem] lg:leading-[8.75rem] font-bold leading-tight mt-8"
      :class="
        locale !== LanguageCode.Ko
          ? 'md:text-[4.8rem] lg:text-[6.4rem] -tracking-[.2rem]'
          : 'md:text-[5.2rem] lg:text-[7rem]'
      "
    >
      <safe-html :html="$t('studio.brand_page.full_service_title2')" />
    </h2>
    <p
      class="text-md leading-lg -tracking-[0.03em] md:text-2xl md:leading-xl font-medium text-on-surface-elevation-2 mt-12 md:mt-16"
    >
      <safe-html :html="$t('studio.brand_page.full_service_guide')" />
    </p>
    <a
      :href="getDocsUrl()"
      target="_blank"
      rel="noopener noreferrer"
      class="items-center justify-center px-[3rem] py-16 md:px-40 lg:px-32 lg:py-[1.9rem] bg-on-surface-elevation-1 text-white rounded-full font-medium text-xl leading-lg lg:text-2xl lg:leading-xl w-max mt-40 hidden md:flex z-[1]"
      :aria-label="$t('studio.direct_dev_guide_page_btn')"
    >
      {{ $t('studio.direct_dev_guide_page_btn') }}
    </a>
    <div
      class="relative w-full h-auto md:px-[0.3rem] lg:pl-[16.7rem] lg:pr-[4.4rem] mt-24 sm:mt-40 md:mt-0 max-w-[76rem] md:max-w-[96rem] lg:max-w-fit mx-auto"
    >
      <img
        ref="sectionImageFooter3Ref"
        src="@/assets/images/brand-page/footer-3.png"
        alt="footer 3"
        class="w-full h-auto"
      />
      <img
        ref="sectionImageFooter1Ref"
        :src="footer1Img"
        alt="footer 1"
        class="absolute top-0 left-0 sm:left-[6.2rem] sm:w-[calc(100%-8.4rem)] md:left-[0.3rem] md:w-[calc(100%-0.6rem)] lg:left-[16.7rem] lg:w-[calc(100%-21rem)]"
      />
      <img
        ref="sectionImageFooter2Ref"
        src="@/assets/images/brand-page/footer-2.png"
        alt="footer 2"
        class="absolute top-0 left-0 sm:left-[6.2rem] sm:w-[calc(100%-8.4rem)] md:left-[0.3rem] md:w-[calc(100%-0.6rem)] lg:left-[16.7rem] lg:w-[calc(100%-21rem)]"
      />
      <img
        ref="sectionImageFooter4Ref"
        src="@/assets/images/brand-page/footer-4.png"
        alt="footer 4"
        class="absolute top-0 left-0 sm:left-[6.2rem] sm:w-[calc(100%-8.4rem)] md:left-[0.3rem] md:w-[calc(100%-0.6rem)] lg:left-[16.7rem] lg:w-[calc(100%-21rem)]"
      />
    </div>
    <a
      :href="getDocsUrl()"
      target="_blank"
      rel="noopener noreferrer"
      class="flex items-center justify-center px-[3rem] py-16 md:px-40 lg:px-32 lg:py-[1.9rem] bg-on-surface-elevation-1 text-white rounded-full font-medium text-xl leading-lg lg:text-2xl lg:leading-xl w-max mt-[6rem] sm:mt-40 md:hidden"
      :aria-label="$t('studio.direct_dev_guide_page_btn')"
    >
      {{ $t('studio.direct_dev_guide_page_btn') }}
    </a>
  </div>
  <div class="text-[#13D4FF] sm:block"></div>
  <div class="sm:hidden md:hidden lg:hidden"></div>
</template>
<script setup lang="ts">
import gsap from 'gsap';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as footer1Images from '@/assets/images/brand-page/footer-1';
import SafeHtml from '@/components/common/safe-html.vue';
import { useApp } from '@/composables/useApp';
import { LanguageCode } from '@/enums/language-code.enum';

type ImagesUrl = {
  [key: string]: string;
};

const { locale } = useI18n();
const { getDocsUrl } = useApp();
const prefix = 'image_';
const footer1Img = (footer1Images as ImagesUrl)[`${prefix}${locale.value.replace('-', '_')}`];

const sectionImageFooter1Ref = ref<HTMLElement | null>(null);
const sectionImageFooter2Ref = ref<HTMLElement | null>(null);
const sectionImageFooter3Ref = ref<HTMLElement | null>(null);
const sectionImageFooter4Ref = ref<HTMLElement | null>(null);
const isMobile = computed(() => window.innerWidth <= 768);

onMounted(() => {
  if (sectionImageFooter1Ref.value) {
    const config = {
      trigger: sectionImageFooter1Ref.value,
      start: isMobile.value ? 'top 90%' : 'top 90%',
      toggleActions: 'play none none none'
    };

    if (isMobile.value) {
      const tlMobile = gsap.timeline({
        scrollTrigger: config
      });
      tlMobile.add(
        gsap.fromTo(
          [sectionImageFooter1Ref.value],
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.75,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tlMobile.add(
        gsap.fromTo(
          [
            sectionImageFooter2Ref.value,
            sectionImageFooter3Ref.value,
            sectionImageFooter4Ref.value
          ],
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.75,
            ease: 'sine.inOut'
          }
        ),
        0.2
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageFooter1Ref.value,
          {
            y: 10
          },
          {
            y: 0,
            duration: 1.5,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageFooter3Ref.value,
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            duration: 2.5,
            ease: 'power3.out'
          }
        ),
        0.25
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageFooter2Ref.value,
          {
            y: 30
          },
          {
            y: 0,
            duration: 2.5,
            ease: 'power3.out'
          }
        ),
        0.25
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageFooter4Ref.value,
          {
            y: 40
          },
          {
            y: 0,
            duration: 3,
            ease: 'power3.out'
          }
        ),
        0.35
      );
    } else {
      const tl = gsap.timeline({
        scrollTrigger: config
      });
      tl.add(
        gsap.fromTo(
          [sectionImageFooter1Ref.value],
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.75,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tl.add(
        gsap.fromTo(
          [
            sectionImageFooter2Ref.value,
            sectionImageFooter3Ref.value,
            sectionImageFooter4Ref.value
          ],
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.75,
            ease: 'sine.inOut'
          }
        ),
        0.2
      );
      tl.add(
        gsap.fromTo(
          sectionImageFooter1Ref.value,
          {
            y: 10
          },
          {
            y: -40,
            duration: 1.5,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tl.add(
        gsap.fromTo(
          sectionImageFooter3Ref.value,
          {
            opacity: 0,
            y: 70
          },
          {
            opacity: 1,
            y: -40,
            duration: 2.5,
            ease: 'power3.out'
          }
        ),
        0.25
      );
      tl.add(
        gsap.fromTo(
          sectionImageFooter2Ref.value,
          {
            y: 70
          },
          {
            y: -40,
            duration: 2.5,
            ease: 'power3.out'
          }
        ),
        0.25
      );
      tl.add(
        gsap.fromTo(
          sectionImageFooter4Ref.value,
          {
            y: 80
          },
          {
            y: -40,
            duration: 3,
            ease: 'power3.out'
          }
        ),
        0.35
      );
    }
  }
});
</script>
