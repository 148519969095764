<template>
  <div
    class="max-w-[136rem] mx-auto px-20 sm:px-40 md:pl-40 md:pr-20 lg:pl-[2.4rem] lg:pr-[1.5rem]"
  >
    <div class="flex flex-col md:flex-row-reverse md:gap-0 lg:gap-40 justify-between items-center">
      <div
        ref="sectionImageShareRef"
        class="flex-1 relative w-full md:px-0 md:shrink-0 md:min-w-[48rem] h-auto lg:w-[65.3rem] max-w-[42rem] sm:max-w-[56rem] lg:max-w-[65.3rem] mx-auto"
      >
        <img
          ref="sectionImageMessage1Ref"
          :src="shareImage1"
          alt="message 1"
          class="absolute top-0 left-0 w-full"
        />
        <img
          ref="sectionImageMessage2Ref"
          :src="shareImage2"
          alt="message 2"
          class="absolute top-0 left-0 w-full"
        />
        <img
          ref="sectionImageMessage3Ref"
          :src="shareImage3"
          alt="message 3"
          class="absolute top-0 left-0 w-full"
        />
        <img :src="shareImage4" alt="social image" class="" />
      </div>
      <div
        class="flex flex-col items-center text-center w-full md:flex-1 md:min-w-[48rem] md:max-w-[56rem] md:text-left md:items-start mt-20 sm:mt-40 md:mt-0 lg:max-w-[62.8rem]"
      >
        <h2
          class="text-[3.6rem] leading-[4.68rem] -tracking-[0.03em] sm:text-[5.2rem] sm:leading-[6.5rem] lg:leading-[8.4rem] font-bold leading-tight"
          :class="
            locale !== LanguageCode.Ko
              ? 'md:text-[4.8rem] lg:text-[6.4rem]'
              : 'md:text-[5.2rem] lg:text-[7rem]'
          "
        >
          <safe-html :html="$t('studio.brand_page.game_review_title')" />
        </h2>

        <safe-html
          tag="p"
          class="text-md leading-lg -tracking-[0.03em] md:text-2xl md:leading-xl font-medium text-on-surface-elevation-2 mt-16 sm:mt-12 md:mt-16"
          :html="$t('studio.brand_page.game_review_guide')"
        />

        <a
          :href="getLoungeUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="flex items-center justify-center px-[3rem] py-16 md:px-40 lg:py-[1.9rem] lg:px-[6.4rem] bg-on-surface-elevation-1 text-white rounded-full font-medium text-xl leading-lg lg:text-2xl lg:leading-xl w-max mt-20 sm:mt-[4rem]"
          :aria-label="$t('studio.direct_stove_lounge_btn')"
        >
          {{ $t('studio.direct_stove_lounge_btn') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import gsap from 'gsap';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as ShareImages from '@/assets/images/brand-page/share-imgs';
import SafeHtml from '@/components/common/safe-html.vue';
import { LanguageCode } from '@/enums/language-code.enum';
import { getConfigs } from '@/utils/common.util';

type ImagesUrl = {
  [key: string]: string;
};

const { locale } = useI18n();
const prefix = 'image';
const locate = locale.value.replace('-', '_');
const shareImage1 = (ShareImages as ImagesUrl)[`${prefix}1_${locate}`];
const shareImage2 = (ShareImages as ImagesUrl)[`${prefix}2_${locate}`];
const shareImage3 = (ShareImages as ImagesUrl)[`${prefix}3_${locate}`];
const shareImage4 = (ShareImages as ImagesUrl)[`${prefix}4_${locate}`];

const sectionImageShareRef = ref<HTMLElement | null>(null);
const sectionImageMessage1Ref = ref<HTMLElement | null>(null);
const sectionImageMessage2Ref = ref<HTMLElement | null>(null);
const sectionImageMessage3Ref = ref<HTMLElement | null>(null);

const isMobile = computed(() => window.innerWidth <= 768);

const getLoungeUrl = computed(() => {
  const { LOUNGE_URL } = getConfigs();
  return LOUNGE_URL;
});

onMounted(() => {
  if (sectionImageShareRef.value) {
    const config = {
      trigger: sectionImageShareRef.value,
      start: isMobile.value ? 'top 90%' : 'top 90%',
      toggleActions: 'play none none none'
    };
    if (isMobile.value) {
      const tlMobile = gsap.timeline({
        scrollTrigger: config
      });

      tlMobile.add(
        gsap.fromTo(
          sectionImageShareRef.value,
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageMessage1Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.35
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageMessage2Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.6
      );
      tlMobile.add(
        gsap.fromTo(
          sectionImageMessage3Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.85
      );
    } else {
      const tl = gsap.timeline({
        scrollTrigger: config
      });

      tl.add(
        gsap.fromTo(
          sectionImageShareRef.value,
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'sine.inOut'
          }
        ),
        0
      );
      tl.add(
        gsap.fromTo(
          sectionImageMessage1Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.35
      );
      tl.add(
        gsap.fromTo(
          sectionImageMessage2Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.6
      );
      tl.add(
        gsap.fromTo(
          sectionImageMessage3Ref.value,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'sine.inOut'
          }
        ),
        0.85
      );
    }
  }
});
</script>
