<template>
  <div
    class="pt-[16.6rem] pb-[7rem] sm:pt-[16.9rem] sm:pb-80 md:pt-[8rem] lg:pt-[16rem] lg:pb-[12rem]"
  >
    <div class="px-20 sm:px-40 lg:px-[1.4rem] max-w-[136rem] mx-auto lg:overflow-visible">
      <h2
        class="text-title4 leading-3xl md:text-title3 md:leading-4xl lg:text-title1 lg:leading-6xl font-bold"
      >
        {{ $t('studio.brand_page.feature_preview') }}
      </h2>
      <div class="swiper-container relative mt-16 md:mt-36 lg:overflow-visible">
        <swiper
          :modules="[Navigation]"
          :slidesPerView="'auto'"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'swiper-button-disabled'
          }"
          class="!overflow-visible"
          :breakpoints="{
            0: {
              spaceBetween: 12
            },
            1024: {
              spaceBetween: 32
            }
          }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="(slide, index) in slides"
            :key="index"
            class="!w-[32rem] md:!w-[40rem] lg:!w-[48rem]"
          >
            <article class="flex flex-col">
              <!-- Image container -->
              <div
                class="h-auto overflow-hidden rounded-[1.5rem] relative"
                :class="slide.isActive ? 'group cursor-pointer' : 'pointer-events-none'"
                @click="() => openVideoDialog(slide)"
              >
                <div
                  v-if="!slide.isActive"
                  class="flex items-center justify-center text-[1.6rem] text-center"
                >
                  <span
                    class="stds-text text-title3 leading-title3 font-bold absolute inset-0 bg-black/50 text-white flex items-center justify-center text-center"
                  >
                    <safe-html :html="ComingSooText" />
                  </span>
                </div>
                <img
                  :src="slide.image"
                  :alt="slide.imageAlt"
                  class="w-full transition-transform duration-300 group-hover:[@media(hover:hover)]:scale-105"
                  width="480"
                  height="270"
                />
                <div
                  v-if="slide.isActive"
                  class="absolute inset-0 opacity-0 group-hover:[@media(hover:hover)]:opacity-[1] md:transition-opacity md:duration-300 hidden md:block"
                >
                  <img
                    src="@/assets/images/brand-page/play-icon.svg"
                    alt="Play icon"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              </div>

              <!-- Text content -->
              <div class="md:pb-[1.3rem] lg:pb-[0.8rem] mt-12 md:mt-24">
                <h3
                  class="text-lg leading-lg font-medium md:text-2xl md:leading-xl md:font-bold line-clamp-1"
                >
                  {{ slide.title }}
                </h3>
                <p
                  class="hidden md:line-clamp-2 mt-4 text-md leading-lg text-on-surface-elevation-2 h-[4.8rem]"
                >
                  {{ slide.description }}
                </p>
              </div>
            </article>
          </swiper-slide>

          <!-- Custom navigation buttons -->
          <div
            class="flex flex-row items-center justify-center md:justify-end md:absolute md:-top-[9rem] md:right-0 gap-32 md:gap-24 mt-16 md:mt-0"
          >
            <button
              class="swiper-button-prev w-28 h-28 md:w-40 md:h-40 rounded-full flex items-center justify-center transition-all duration-300 ease-in-out cursor-pointer hover:[@media(hover:hover)]:md:bg-[#E1EFFF] active:md:bg-[#E1EFFF] bg-neutral-variant-3 md:bg-transparent"
            >
              <s-icon
                size="3xl"
                :icon="'ic-v2-control-arrow-left-line'"
                srOnlyText="arrow-left"
                class="text-on-surface-elevation-2 text-[2rem] md:text-title4 items-center w-20 h-20 md:hidden"
              />
              <s-icon
                size="3xl"
                icon="ic-v2-control-long-arrow-left-line"
                srOnlyText="arrow-left"
                class="hidden md:flex text-on-surface-elevation-2 text-title4 items-center w-24 h-24"
              />
            </button>
            <span
              class="md:hidden flex flex-row items-center gap-4 text-on-surface-elevation-4 text-[1.5rem]"
            >
              <span class="font-bold text-on-surface-elevation-1">{{ currentSlide }}</span>
              <span>/</span>
              <span>{{ totalSlides }}</span>
            </span>
            <button
              class="swiper-button-next w-28 h-28 md:w-40 md:h-40 rounded-full flex items-center justify-center transition-all duration-300 ease-in-out cursor-pointer hover:[@media(hover:hover)]:md:bg-[#E1EFFF] active:md:bg-[#E1EFFF] bg-neutral-variant-3 md:bg-transparent"
            >
              <s-icon
                size="3xl"
                :icon="'ic-v2-control-arrow-right-line'"
                srOnlyText="arrow-right"
                class="text-on-surface-elevation-2 text-[2rem] md:text-title4 items-center w-20 h-20 md:hidden"
              />
              <s-icon
                size="3xl"
                icon="ic-v2-control-long-arrow-right-line"
                srOnlyText="arrow-left"
                class="hidden md:flex text-on-surface-elevation-2 text-title4 items-center w-24 h-24"
              />
            </button>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/pagination';

import { Navigation } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper/types/index.d.ts';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { shallowRef } from '@/.nuxt/imports';
import { useLocalizedImage } from '@/assets/images/tutorial';
import VideoContentDialog from '@/components/brand-page/video-content-dialog.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import { showDialog } from '@/composables/useDialog';
import { INTRO_VIDEO_URLS } from '@/constants/videos.const';
import { LanguageCode } from '@/enums/language-code.enum';

type Slide = {
  image: string;
  imageAlt: string;
  title: string;
  description: string;
  srcVideo: string;
  isActive: boolean;
};

const { t, locale } = useI18n();

const ComingSooText = computed(() => {
  return locale.value === 'ko'
    ? '튜토리얼 영상<br />공개 예정'
    : 'Tutorial Video<br />Coming Soon';
});

const currentSlide = ref(1);

const totalSlides = computed(() => slides.length);

const SliderImg2 = useLocalizedImage('ImageVideo2');
const SliderImg3 = useLocalizedImage('ImageVideo3');
const SliderImg4 = useLocalizedImage('ImageVideo4');
const SliderImg5 = useLocalizedImage('ImageVideo5');
const SliderImg6 = useLocalizedImage('ImageVideo6');
const SliderImg7 = useLocalizedImage('ImageVideo7');
const SliderImg8 = useLocalizedImage('ImageVideo8');
const SliderImg9 = useLocalizedImage('ImageVideo9');

const videoUrl = computed(() => {
  return INTRO_VIDEO_URLS[locale.value] || INTRO_VIDEO_URLS[LanguageCode.Ko];
});

const slides: Slide[] = [
  {
    image: SliderImg2,
    imageAlt: 'Project management',
    title: t('studio.brand_page.feature2_main_title'),
    description: t('studio.brand_page.feature2_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg3,
    imageAlt: 'Product Home and Product Settings',
    title: t('studio.brand_page.feature3_main_title'),
    description: t('studio.brand_page.feature3_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg4,
    imageAlt: 'Product Page',
    title: t('studio.brand_page.feature4_main_title'),
    description: t('studio.brand_page.feature4_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg5,
    imageAlt: 'Upload Build',
    title: t('studio.brand_page.feature5_main_title'),
    description: t('studio.brand_page.feature5_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg6,
    imageAlt: 'Register Business',
    title: t('studio.brand_page.feature6_main_title'),
    description: t('studio.brand_page.feature6_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg7,
    imageAlt: 'Price and Policies',
    title: t('studio.brand_page.feature7_main_title'),
    description: t('studio.brand_page.feature7_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg8,
    imageAlt: 'Official Release',
    title: t('studio.brand_page.feature8_main_title'),
    description: t('studio.brand_page.feature8_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: SliderImg9,
    imageAlt: 'Discounts',
    title: t('studio.brand_page.feature9_main_title'),
    description: t('studio.brand_page.feature9_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  }
];

const onSwiper = (_swiper: SwiperType) => {
  // console.log('Swiper instance:', swiper);
  // console.log('Current breakpoint:', swiper.currentBreakpoint);
  // console.log('Current spaceBetween:', swiper.params.spaceBetween);
};

const onSlideChange = (swiper: SwiperType) => {
  currentSlide.value = swiper.activeIndex + 1;
};

const openVideoDialog = (slide: Slide) => {
  if (!slide.isActive) { return; }
  showDialog({
    component: shallowRef(VideoContentDialog),
    props: {
      thumbnailImage: slide.image,
      imageAlt: slide.imageAlt,
      title: slide.title,
      description: slide.description,
      srcVideo: slide.srcVideo
    },
    severity: 'info'
  });
};
</script>
<style scoped>
:deep(.swiper-button-prev::after),
:deep(.swiper-button-next::after) {
  display: none;
}
:deep(.swiper-button-disabled) {
  @apply cursor-not-allowed pointer-events-none;
}

:deep(.swiper-button-disabled i) {
  @apply text-disabled-variant-1;
}

:deep(.swiper-wrapper) {
  @apply flex;
}
</style>
