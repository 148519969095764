<template>
  <div
    class="flex flex-col-reverse md:flex-row justify-between px-20 sm:px-40 lg:px-0"
    :class="locale !== LanguageCode.Ko ? 'lg:gap-40' : ''"
  >
    <div
      ref="sectionTitleRef"
      class="w-full lg:w-[67rem]"
      :class="
        locale !== LanguageCode.Ko
          ? 'max-w-[56rem] sm:max-w-[80rem] md:max-w-[72rem] lg:max-w-fit'
          : 'max-w-[56rem] sm:max-w-[80rem] md:max-w-fit lg:max-w-fit'
      "
    >
      <p
        class="text-2xl leading-xl font-bold sm:text-3xl sm:leading-2xl md:font-medium mt-32 sm:mt-40 md:mt-0"
      >
        <safe-html :html="$t('studio.brand_page.all_in_one_go_title')" />
      </p>
      <h2
        class="text-[3.6rem] leading-[4.68rem] sm:text-[5.2rem] sm:leading-[6.5rem] md:leading-[6.24rem] lg:leading-[8.4rem] font-bold leading-tight mt-8 sm:mt-12 md:mt-8 -tracking-[0.03em]"
        :class="
          locale !== LanguageCode.Ko
            ? 'md:text-[4.8rem] lg:text-[6.4rem]'
            : 'md:text-[5.2rem] lg:text-[7rem]'
        "
      >
        <safe-html :html="$t('studio.brand_page.all_in_one_go_guide')" />
      </h2>
      <a
        :href="storeUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center justify-center px-[3rem] py-16 md:px-32 lg:px-[6.4rem] lg:py-[1.9rem] bg-on-surface-elevation-1 text-white rounded-full font-medium text-xl leading-lg lg:text-2xl lg:leading-xl w-max mt-24 sm:mt-40 lg:mt-[6rem]"
        :aria-label="$t('studio.brand_page.store_btn')"
      >
        {{ $t('studio.brand_page.store_btn') }}
      </a>
    </div>
    <div
      class="flex justify-end mx-auto md:mx-auto w-full max-w-[36rem] sm:max-w-[56rem] md:max-w-[46rem] lg:max-w-fit flex-shrink-0"
    >
      <div class="relative w-full">
        <img
          ref="sectionCoinImageRef"
          src="@/assets/images/brand-page/coin.png"
          alt="coin image"
          class="absolute top-0 left-0"
        />
        <img
          ref="sectionTagImageRef"
          src="@/assets/images/brand-page/tag.png"
          alt="tag image"
          class=""
        />
        <img
          ref="sectionStoveImageRef"
          src="@/assets/images/brand-page/stove.png"
          alt="stove image"
          class="absolute top-0 left-0"
        />
      </div>
    </div>
  </div>
  <!-- <div class="px-20 sm:px-40 lg:px-0">
    <div
      ref="sectionSupportRef"
      class="w-full bg-white rounded-[2rem] md:rounded-[4rem] flex flex-col md:flex-row gap-40 px-24 py-[3rem] md:gap-36 md:px-32 md:py-40 lg:gap-40 lg:px-[6rem] lg:py-[8rem] mt-[6rem] md:mt-[8rem] lg:mt-[7.4rem] shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)]"
    >
      <div class="flex-1 flex flex-col justify-start text-on-surface-elevation-2">
        <s-icon
          :icon="'ic-v2-object-rating-line'"
          srOnlyText="sync icon"
          class="text-brand-primary !text-[4rem] lg:text-[4.8rem] items-center w-40 h-40 lg:w-48 lg:h-48"
        />
        <h3
          class="text-2xl leading-xl -tracking-[0.03em] sm:text-3xl sm:leading-2xl lg:text-4xl lg:leading-3xl font-bold mt-12 md:mt-24"
        >
          <safe-html :html="$t('studio.brand_page.stove_rating_title')" />
        </h3>
        <p
          class="text-lg leading-lg -tracking-[0.03em] sm:text-xl sm:leading-xl md:leading-lg lg:text-2xl lg:leading-xl font-medium mt-4 md:mt-8"
        >
          <safe-html :html="$t('studio.brand_page.stove_rating_guide')" />
        </p>
      </div>
      <div class="flex-1 flex flex-col justify-start">
        <s-icon
          :icon="'ic-v2-navigation-community-line'"
          srOnlyText="sync icon"
          class="text-brand-primary !text-[4rem] lg:text-[4.8rem] items-center w-40 h-40 lg:w-48 lg:h-48"
        />
        <h3
          class="text-2xl leading-xl -tracking-[0.03em] sm:text-3xl sm:leading-2xl lg:text-4xl lg:leading-3xl font-bold mt-12 md:mt-24"
        >
          <safe-html :html="$t('studio.brand_page.stove_marketing_title')" />
        </h3>
        <p
          class="text-lg leading-lg -tracking-[0.03em] sm:text-xl sm:leading-xl md:leading-lg lg:text-2xl lg:leading-xl font-medium mt-4 md:mt-8"
        >
          <safe-html :html="$t('studio.brand_page.stove_marketing_guide')" />
        </p>
      </div>
      <div class="flex-1 flex flex-col justify-start">
        <s-icon
          :icon="'ic-v2-navigation-shop-line'"
          srOnlyText="sync icon"
          class="text-brand-primary !text-[4rem] lg:text-[4.8rem] items-center w-40 h-40 lg:w-48 lg:h-48"
        />
        <h3
          class="text-2xl leading-xl -tracking-[0.03em] sm:text-3xl sm:leading-2xl lg:text-4xl lg:leading-3xl font-bold mt-12 md:mt-24"
        >
          <safe-html :html="$t('studio.brand_page.stove_event_title')" />
        </h3>
        <p
          class="text-lg leading-lg -tracking-[0.03em] sm:text-xl sm:leading-xl md:leading-lg lg:text-2xl lg:leading-xl font-medium mt-4 md:mt-8"
        >
          <safe-html :html="$t('studio.brand_page.stove_event_guide')" />
        </p>
      </div>
    </div>
    <img
      ref="sectionCoinEmptyImageRef"
      src="@/assets/images/brand-page/coin-empty.svg"
      alt="coin image"
      class="absolute bottom-[0] -top-[2.4rem] -right-[2.95rem] w-[7.85rem] -rotate-90 md:rotate-0 md:w-auto md:bottom-[30.3rem] md:top-auto md:-left-[13.3rem] hidden lg:block"
    />
  </div> -->
</template>
<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import { LanguageCode } from '@/enums/language-code.enum';
import { getConfigs } from '@/utils/common.util';

const { locale } = useI18n();

const sectionTitleRef = ref<HTMLElement | null>(null);
const sectionStoveImageRef = ref<HTMLElement | null>(null);
const sectionCoinImageRef = ref<HTMLElement | null>(null);
const sectionTagImageRef = ref<HTMLElement | null>(null);
// const sectionSupportRef = ref<HTMLElement | null>(null);
// const sectionCoinEmptyImageRef = ref<HTMLElement | null>(null);
const isMobile = computed(() => window.innerWidth <= 768);

const storeUrl = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${locale.value}`;
});

gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  if (sectionTitleRef.value) {
    const config = {
      trigger: sectionTitleRef.value,
      start: isMobile.value ? 'top 110%' : 'top 85%',
      end: isMobile.value ? 'bottom -50%' : 'bottom -10%',
      toggleActions: 'play none none none'
    };
    const tl = gsap.timeline({
      scrollTrigger: config
    });

    if (isMobile.value) {
      tl.add(
        gsap.fromTo(
          [sectionTitleRef.value, sectionStoveImageRef.value],
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: 'sine.inOut'
          }
        ),
        0
      );

      tl.add(
        gsap.fromTo(
          [sectionCoinImageRef.value, sectionTagImageRef.value],
          {
            opacity: 0,
            y: -20
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: 'sine.inOut'
          }
        ),
        0
      );

      // tl.add(
      //   gsap.fromTo(
      //     sectionSupportRef.value,
      //     {
      //       opacity: 0,
      //       y: 30
      //     },
      //     {
      //       opacity: 1,
      //       y: 0,
      //       duration: 0.8,
      //       ease: 'sine.inOut'
      //     }
      //   ),
      //   0.5
      // );

      // tl.add(
      //   gsap.fromTo(
      //     sectionCoinEmptyImageRef.value,
      //     {
      //       opacity: 0
      //     },
      //     {
      //       opacity: 1,
      //       duration: 0.5,
      //       ease: 'sine.inOut'
      //     }
      //   ),
      //   0.5
      // );
    } else {
      tl.add(
        gsap.fromTo(
          [sectionTitleRef.value, sectionStoveImageRef.value],
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'sine.inOut'
          }
        ),
        0
      );

      tl.add(
        gsap.fromTo(
          [sectionCoinImageRef.value, sectionTagImageRef.value],
          {
            opacity: 0,
            y: -30
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'sine.inOut'
          }
        ),
        0
      );

      // tl.add(
      //   gsap.fromTo(
      //     sectionSupportRef.value,
      //     {
      //       opacity: 0,
      //       y: 30
      //     },
      //     {
      //       opacity: 1,
      //       y: 0,
      //       duration: 1,
      //       ease: 'sine.inOut'
      //     }
      //   ),
      //   0.5
      // );

      // tl.add(
      //   gsap.fromTo(
      //     sectionCoinEmptyImageRef.value,
      //     {
      //       opacity: 0,
      //       x: -20
      //     },
      //     {
      //       opacity: 1,
      //       x: 0,
      //       duration: 0.8,
      //       ease: 'sine.inOut'
      //     }
      //   ),
      //   0.5
      // );
    }
  }
});

onUnmounted(() => {});
</script>
